<template>
  <div>
    <p-button
      v-if="promoContext"
      type="warning"
      class="mr-2"
      :title="$t('messenger_context_promo_info')"
      @click="togglePromoContext"
    >
      <i slot="label" class="fa fa-envelope"></i>
      {{ $t('messenger_context_promo') }}
    </p-button>
    <p-button
      v-else
      type="info"
      class="mr-2"
      :title="$t('messenger_context_system_info')"
      @click="togglePromoContext"
    >
      <i slot="label" class="fa fa-cogs"></i>
      {{ $t('messenger_context_system') }}
    </p-button>
    <p-button type="primary" @click="addTemplate">
      <i slot="label" class="fa fa-plus"></i>
    </p-button>
    <p-button type="primary" @click="editTemplate">
      <i slot="label" class="fa fa-edit"></i>
    </p-button>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  computed: {
    ...mapState('messenger', ['currentRow', 'promoContext']),
  },
  methods: {
    ...mapActions('messenger', ['addTemplate', 'openFormModal', 'togglePromoContext']),
    editTemplate() {
      if(this.currentRow && !this.currentRow.template) {
        this.$toast.warning(this.$t('selectarow'))
        return
      }
      this.openFormModal();
    },
  },
}
</script>

<style scoped>

</style>
