<template>
  <modal :show.sync="showDispatchExternalModal" :required="true" headerClasses="justify-content-center"
         modalClasses="modal-lg">
    <h4 slot="header" class="title title-up">{{ $t('dispatchcurrenttitle') }}</h4>

    <div class="row" v-if="currentRow">
      <fieldset>
        <div class="row">
          <fg-input class="col-12" v-model="customTag" :label="$t('customtagtxt')"></fg-input>
        </div>
        <div class="row">
          <div class="form-group col-12 has-label">
            <label>{{ $t('customcsvtxt') }}</label>
            <input class="form-control-file" type="file" ref="file" v-on:change="handleFileUpload()"></input>
          </div>
        </div>
        <hr>
        <label class="model">{{ $t('template') }}: {{ currentRow.template }} ({{ currentRow.lang }})</label>
        <div class="form-group">
          <span><b>{{ $t('messagesubject') }}:</b></span><span> {{ currentRow.subject }}</span><br>
          <span><b>{{ $t('preview') }}:</b></span>
        </div>
        <div class="preview" v-html="currentRow.body"></div>
      </fieldset>

    </div>

    <template slot="footer">
      <div class="left-side">
        <p-button link @click="startSending">{{ $t('sendpromo') }}</p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button type="danger" link @click="cleanDispatcher">{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Modal from 'src/components/UIComponents/Modal';
import swal from 'sweetalert2';

export default {
  components: {Modal},
  computed: {
    ...mapState('messenger', ['showDispatchExternalModal', 'currentRow']),
  },
  data() {
    return {
      customTag: '',
      file: '',
    }
  },
  methods: {
    ...mapActions('messenger', ['clean']),
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    cleanDispatcher() {
      this.customTag = '';
      this.file= '';
      this.clean();
    },
    startSending() {
      swal({
        title: this.$t('needconfirmation'),
        text: this.$t('sendpromoexternalconfirmation'),
        type: 'warning',
        showCancelButton: true,
      })
        .then(() => {
          let formData = new FormData();
          formData.append('file', this.file);
          formData.append('tag', this.customTag);
          this.$sendPromoEmailToCsv(this.currentRow, formData)
              .then(this.successCallback, this.failCallback)
              .catch(this.failCallback);
        })
        .catch(swal.noop);
    },
    successCallback(response) {
      if (!response.success) {
        this.$toast.warning(this.$t(response.msg));
        return;
      }
      this.$toast.success(this.$t(response.msg));
    },
    failCallback(error) {
      this.$toast.error(this.$t('unkownerror'));
      console.log(error);
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';

.preview {
  width: 100%;
  height: 300px;
  overflow: scroll;
  border: $border $light-gray;
}

.btn.btn-sm {
  height: $height-base;
  margin-top: 25px;
}

fieldset {
  width: 100%;
  .form-group input[type="file"] {
    opacity: 1 !important;
    position: relative;
  }
}

label.model {
  font-weight: bold;
  font-size: $font-size-medium;
  color: $font-color;
}
</style>
