<template>
  <modal :show.sync="showDispatchModal" :required="true" headerClasses="justify-content-center" modalClasses="modal-lg">
    <h4 slot="header" class="title title-up">{{ $t('dispatchcurrenttitle') }}</h4>

    <div class="row" v-if="currentRow">
      <fieldset>
        <div class="row">
          <div class="col-md-6">
            <label>{{ $t('template') }}: {{ currentRow.template }} ({{ currentRow.lang }})</label>
            <div class="form-group">
              <span><b>{{ $t('messagesubject') }}:</b></span><span> {{ currentRow.subject }}</span><br>
              <span><b>{{ $t('preview') }}:</b></span>
            </div>
          </div>
        </div>
        <div class="preview" v-html="currentRow.body"></div>
        <label class="mt-4">Select which mailists is to send to:</label>
        <el-checkbox-group v-model="checkGroup" size="mini">
          <el-checkbox label="hoko" key="hoko">Send to HokoCloud users</el-checkbox>
          <el-checkbox label="wl" key="wl">Send to Whitelabel users</el-checkbox>
        </el-checkbox-group>
      </fieldset>

    </div>

    <template slot="footer">
      <div class="left-side">
        <p-button link type="warning" @click="startSending">{{ $t('sendpromo') }}</p-button>
      </div>
      <div class="divider"></div>
      <div class="left-side">
        <p-button type="primary" link @click="dispatchTest()">{{ $t('templatetest') }}</p-button>
      </div>
      <div class="divider"></div>
      <div class="right-side">
        <p-button type="danger" link @click="cleanDispatcher">{{ $t('cancel') }}</p-button>
      </div>
    </template>
  </modal>
</template>

<script>
import Vue from 'vue';
import {mapActions, mapState} from 'vuex';
import Modal from 'src/components/UIComponents/Modal';
import {CheckboxGroup, Checkbox} from 'element-ui';
import swal from 'sweetalert2';

Vue.use(CheckboxGroup);
Vue.use(Checkbox);

export default {
  components: {Modal},
  computed: {
    ...mapState('messenger', ['showDispatchModal', 'currentRow']),
  },
  data() {
    return {
      checkGroup: ['hoko'],
    }
  },
  methods: {
    ...mapActions('messenger', ['clean']),
    dispatchTest() {
      swal({
        title: this.$t('needconfirmation'),
        text: this.$t('templatetest'),
        type: 'warning',
        showCancelButton: true,
      })
        .then(() => {
          this.$testPromoEmail({
            template: this.currentRow.template,
            lang: this.currentRow.lang
          })
            .then(this.successCallback)
            .catch(this.failCallback);
        })
        .catch(swal.noop);
    },
    cleanDispatcher() {
      this.clean();
    },
    startSending() {
      if(!this.checkGroup.length) {
        this.$toast.warning('Select at least one mailist!');
        return;
      }
      swal({
        title: this.$t('needconfirmation'),
        text: this.$t('sendpromoconfirmation'),
        type: 'warning',
        showCancelButton: true,
      })
      .then(() => {
        this.$sendPromoEmail({
          template: this.currentRow.template,
          send_common: this.checkGroup.includes('hoko'),
          send_whitelabel: this.checkGroup.includes('wl'),
        })
        .then(this.successCallback)
        .catch(this.failCallback);
      })
      .catch(swal.noop);
    },
    successCallback(response) {
      if(!response.success) {
        this.$toast.warning(this.$t(response.msg));
        return;
      }
      this.$toast.success(this.$t(response.msg));
    },
    failCallback(error) {
      this.$toast.error(this.$t('unkownerror'));
      console.log(error);
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
.preview {
  width: 100%;
  height: 300px;
  overflow: scroll;
  border: $border $light-gray;
}
.btn.btn-sm {
  height: $height-base;
  margin-top: 25px;
}
fieldset {
  width: 100%;
}
label {
  font-weight: bold;
  font-size: $font-size-medium;
  color: $font-color;
}
</style>
