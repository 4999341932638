<template>
  <div class="form-group">
    <label>{{ $t('type') }}</label>
    <el-select v-on="$listeners" v-bind="$attrs" :placeholder="$t('helper_allocationmethodtxt')">
      <el-option value="">
        {{ $t('helper_allocationmethodtxt') }}
      </el-option>
      <el-option
        v-for="option in options"
        :key="option"
        :value="option"
        :title="$t(`messenger_type_info_${option}`)"
      >
        {{ option }}
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        'email',
        'push',
      ]
    }
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
label {
  font-size: $font-size-small;
  margin-bottom: 5px;
  color: $dark-gray;
}
</style>
