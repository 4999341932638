<template>
   <module
    ref="module"
    id="messenger"
    titleIcon="fa fa-envelope"
    :title="$t('messenger')"
    :filters.sync="filters"
    :fixedFilters="fixedFilters"
    api-url="MESSENGERURL"
    url="/template/search"
    @on-select="passCurrentRow"
  >
     <template slot="toolbar-lst" slot-scope="{module}">
       <messenger-toolbar></messenger-toolbar>
     </template>
     <template slot="global">
       <messenger-dispatch-modal></messenger-dispatch-modal>
       <messenger-dispatch-external-modal></messenger-dispatch-external-modal>
       <messenger-form-modal></messenger-form-modal>
     </template>
    <template slot="table-columns">
      <el-table-column v-if="promoContext" width="160" :label="$t('dispatch')" align="center">
        <template slot-scope="{row}">
          <p-button type="primary"
                    size="sm"
                    class="mr-1"
                    :title="$t('sendpromotitle')"
                    @click="startDispatch(row)"
          >
            <i slot="label" class="fa fa-envelope"></i>
          </p-button>
          <p-button type="warning"
                    size="sm"
                    :title="$t('sendpromocsvtitle')"
                    @click="startDispatchExternal(row)"
          >
            <i slot="label" class="fa fa-envelope"></i>
          </p-button>
        </template>
      </el-table-column>
      <el-table-column prop="template" :label="$t('template')" sortable="custom"></el-table-column>
      <el-table-column prop="type" width="150" :label="$t('type')" sortable="custom"></el-table-column>
      <el-table-column prop="subtype" width="150" :label="$t('subtype')" sortable="custom"></el-table-column>
      <el-table-column width="120" :label="$t('user_lang')">
        <template slot-scope="props">
          <img :src="getFlag(props.row.lang)" alt="flag" class="dropdown-toggle"> {{ props.row.lang }}
        </template>
      </el-table-column>
    </template>
    <template slot="filter-field">
      <fg-input v-model="filters.template" :label="$t('template')" />
      <messenger-type-select v-model="filters.type" v-if="!promoContext" />
      <messenger-subtype-select v-model="filters.subtype" :current-type="filters.type" v-if="!promoContext" />
      <messenger-language-select v-model="filters.lang" />
    </template>
  </module>
</template>

<script>
import {mapState, mapActions} from "vuex";
import MessengerToolbar from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerToolbar";
import MessengerDispatchModal from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerDispatchModal";
import MessengerLanguageSelect from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerLanguageSelect";
import MessengerTypeSelect from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerTypeSelect";
import MessengerSubtypeSelect from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerSubtypeSelect";
import MessengerFormModal from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerFormModal";
import MessengerDispatchExternalModal
  from "@/components/Dashboard/Views/Dashboard/Messenger/MessengerDispatchExternalModal";

export default {
  components: {
    MessengerDispatchExternalModal,
    MessengerFormModal,
    MessengerLanguageSelect,
    MessengerTypeSelect,
    MessengerSubtypeSelect,
    MessengerDispatchModal,
    MessengerToolbar
  },
  computed: {
    ...mapState('messenger', ['currentRow', 'promoContext']),
    configTable() {
      return {
        height: document.querySelector('.content').clientHeight - 255,
        highlightCurrentRow: true,
      };
    },
    fixedFilters() {
      return {
        type: this.promoContext ? 'email' : this.filters.type || '',
        subtype: this.promoContext ? 'promo' : this.filters.subtype || '',
      };
    },
  },
  data () {
    return {
      inProgress: true,
      filters: {
        template: '',
        lang: '',
        type: this.promoContext ? 'email' : '',
        subtype: this.promoContext ? 'promo' : '',
      },
    }
  },
  methods: {
    ...mapActions('messenger', ['startDispatch', 'startDispatchExternal', 'setCurrentRow']),
    getFlag(lang) {
      const flag = lang.toUpperCase() === 'EN' ? 'GB' : 'PT';
      return `/static/img/flags/${flag}.png`;
    },
    passCurrentRow(module) {
      this.setCurrentRow(module.selectedRow);
    },
  },
  mounted() {
    this.inProgress = false;
  },
  watch: {
    currentRow(value) {
      if(value && !value.template) {
        this.$refs
            .module
            .getDatatableRef()
            .then(datatable => datatable.queryChange('refresh'));
      }
    },
    promoContext(value) {
      this.$nextTick(() => this.$refs.module.search());
    },
  },
}
</script>

<style scoped lang="scss">
@import './src/assets/sass/paper/variables';
h5 {
  display: inline-block;
  padding: 1rem;
  margin: 0;
  i {
    padding-right: 1rem;
  }
}
.el-main {
  padding-top: 0;
  padding-bottom: 0;
}
.el-aside {
  border-left: $border $light-gray;
  fieldset {
    padding: 0 0 0 $padding-input-vertical;
    > label {
      font-weight: bold;
      font-size: $font-size-medium;
      color: $font-color;
    }
  }
}
</style>
